<template>
  <div class="content-wrap">
      <el-tabs v-model="activeName" @tab-click="handleInvoiceOpenTabsChange">
        <el-tab-pane label="发票税率管理" name="taxRate" lazy>
          <invoiceTaxRate v-if="activeName == 'taxRate'"></invoiceTaxRate>
        </el-tab-pane>
        <el-tab-pane label="发票类型管理" name="type" lazy>
          <invoiceType v-if="activeName == 'type'"></invoiceType>
        </el-tab-pane>
         <el-tab-pane label="限行拆分规则" name="split" lazy>
          <splitRule v-if="activeName == 'split'"></splitRule>
        </el-tab-pane>
         <el-tab-pane label="开票基础配置" name="basics" lazy>
          <!-- <electron-wait-invoice v-if="isRetail && activeName == 'wait'" :billingTradeIds="billingTradeIds"></electron-wait-invoice> -->
          <list v-if="activeName == 'basics'"></list>
        </el-tab-pane>
      </el-tabs>
   
  </div>
</template>

<script>
import invoiceTaxRate from './invoiceOpen/invoiceTaxRate.vue'
import invoiceType from './invoiceOpen/invoiceType.vue'
import splitRule from './invoiceOpen/splitRule.vue'
import list from './invoiceOpen/List.vue'


export default {
  name: 'invoiceOpenConfiguration',
  components: {
    splitRule,
    invoiceTaxRate,
    invoiceType,
    list
  },
  data() {
    return {
      activeName: ''
    };
  },
  computed: {
    isRetail() {
      return true;
    }
  },
  created() {
    this.activeName = 'type';
  },
  methods: {
    // 发票开具Tabs切换
    handleInvoiceOpenTabsChange(tab) {
      this.activeName = tab.name;
    }
  }
};
</script>

<style lang="scss" scoped>
.content-select {
  width: 280px;
  position: relative;
  top: 16px;
  left: calc(100% - 350px);
  z-index: 200;

  label {
    color: #333333;
    font-weight: 400;
    font-size: 16px;
  }

  ::v-deep .el-input__inner {
    border: none;
    border-radius: 0;
  }
}

.content-tab {
  margin-top: -24px;
  z-index: 1;
}

::v-deep .el-tabs__item {
  margin-top: 7px;
  color: #333333;
  font-weight: 400;
  font-size: 16px;
}

::v-deep .el-tabs__item.is-active {
  color: #3d94ff;
}
</style>

